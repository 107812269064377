import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'
import ReduxSagaFirebase from 'redux-saga-firebase';
const firebaseConfig = {
  apiKey: "AIzaSyAcrmxKvxk_mmD3n4beiUo-lUWW7_u9QLI",
  authDomain: "greendepartment-invoice.firebaseapp.com",
  databaseURL: "https://greendepartment-invoice.firebaseio.com",
  projectId: "greendepartment-invoice",
  storageBucket: "greendepartment-invoice.appspot.com",
  messagingSenderId: "303683843126",
  appId: "1:303683843126:web:390df1ad231ce8493a9497",
  measurementId: "G-KWJHBBK06N"
};
/*
const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};
 const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
 !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
*/

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const rsf = new ReduxSagaFirebase(firebaseApp);
export default firebase;
