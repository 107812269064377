import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';



const httpLink = createHttpLink({
  uri: 'https://api.monday.com/v2',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE0NjA5Mzk0NywidWlkIjoxNzQxNDgzMCwiaWFkIjoiMjAyMi0wMi0xNVQxNDoyNjo0OS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6NTM3ODk1OSwicmduIjoidXNlMSJ9.1HzfoA077t0dK6T2m--xU_yaaJ9hFk0uwPTVLI80Wq8',
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client